'use client';

import { useSearchParams } from 'next/navigation';
import { FC, createContext, useEffect, useState } from 'react';

import { ErrorBoundary } from '../_components/common';
import CreateFormModal, {
  CreateFormModalProps,
} from '../_components/form/CreateFormModal/CreateFormModal';
import ChangePlanPeriodFormModal, {
  ChangePlanPeriodModalProps,
} from '../_components/team/ChangePlanModal/ChangePlanModal';
import CreateTeamModal, {
  CreateTeamModalProps,
} from '../_components/team/CreateTeamModal/CreateTeamModal';
import DeleteTeamModal, {
  DeleteTeamModalProps,
} from '../_components/team/DeleteTeamModal/DeleteTeamModal';
import LeaveTeamModal, {
  LeaveTeamModalProps,
} from '../_components/team/LeaveTeamModal/LeaveTeamModal';
import MemberListModal, {
  MemberListModalProps,
} from '../_components/team/MemberListModal/MemberListModal';
import { MemberListProvider } from './MemberListContext';
import { TeamTier } from './TeamTierContext';

type OmitModalProps<T> = Omit<T, 'open' | 'setOpen'>;

type CreateFormType = {
  type: 'create_form';
} & OmitModalProps<CreateFormModalProps>;

type ChanePlanPeriodType = {
  type: 'change_plan';
  activeTier: TeamTier;
} & OmitModalProps<ChangePlanPeriodModalProps>;

type CreateTeamType = {
  type: 'create_team';
} & OmitModalProps<CreateTeamModalProps>;

type MemberListType = {
  type: 'member_list';
} & OmitModalProps<MemberListModalProps>;

type LeaveTeamFormType = {
  type: 'leave_team';
} & OmitModalProps<LeaveTeamModalProps>;

type DeleteTeamFormType = {
  type: 'delete_team';
} & OmitModalProps<DeleteTeamModalProps>;

type OpenModalParams =
  | CreateFormType
  | CreateTeamType
  | MemberListType
  | LeaveTeamFormType
  | DeleteTeamFormType
  | ChanePlanPeriodType;

type DashboardModalContextType = {
  closeModal: () => void;
  // eslint-disable-next-line no-unused-vars
  openModal: (props: OpenModalParams) => void;
  isOpen: boolean;
};

export const DashboardModalContext = createContext<DashboardModalContextType>({
  closeModal: () => {},
  openModal: () => {},
  isOpen: false,
});

const ModalMapper = {
  create_form: CreateFormModal,
  create_team: CreateTeamModal,
  member_list: ({
    open,
    setOpen,
  }: {
    open: boolean;
    setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  }) => (
    <MemberListProvider>
      <MemberListModal open={open} setOpen={setOpen} />
    </MemberListProvider>
  ),
  leave_team: LeaveTeamModal,
  delete_team: DeleteTeamModal,
  change_plan: ChangePlanPeriodFormModal,
};

type DashboardModalProviderProps = {
  children: React.ReactNode;
};

const DashboardModalProvider: FC<DashboardModalProviderProps> = ({
  children,
}) => {
  const searchParams = useSearchParams();
  const action = searchParams.get('action');
  const args = searchParams.get('args');

  const [isOpen, setIsOpen] = useState(() =>
    action ? action in ModalMapper : false,
  );
  const [props, setProps] = useState<OpenModalParams | null>(() => {
    if (!action || !args)
      return {
        type: action,
      };
    try {
      return {
        type: action,
        ...JSON.parse(args),
      };
    } catch (error) {
      return {
        type: action,
      };
    }
  });

  const Modal = props ? ModalMapper[props.type] : null;

  useEffect(() => {
    if (!isOpen && searchParams.get('action')) {
      window.history.replaceState(null, '', location.pathname);
    }
  }, [isOpen]);

  return (
    <DashboardModalContext.Provider
      value={{
        closeModal: () => {
          setIsOpen(false);
          setProps(null);
        },
        openModal: (newProps) => {
          setIsOpen(true);
          setProps(newProps);
        },
        isOpen,
      }}
    >
      {children}
      <ErrorBoundary
        onError={() => {
          setIsOpen(false);
          setProps(null);
        }}
      >
        {Modal && (
          <Modal open={isOpen} setOpen={setIsOpen} {...(props as any)} />
        )}
      </ErrorBoundary>
    </DashboardModalContext.Provider>
  );
};

export default DashboardModalProvider;
