'use client';

import { Paddle, initializePaddle } from '@paddle/paddle-js';
import { useMutation } from '@tanstack/react-query';
import axios from 'axios';
import { createContext, useEffect, useState } from 'react';
import { toast } from '~/components/ui/use-toast';

import { useAuth } from '../hooks/AuthProvider';
import { useTeamTier } from '../hooks/useTeamTier/useTeamTier';

interface PaddleContextType {
  paddle?: Paddle;
  openCheckout: (params: {
    newCheckout?: {
      teamId: string;
      subscriptionId: string | null | undefined;
      priceId: string;
      quantity?: number;
      successUrl?: string;
    };
    updateCheckout?: {
      subscriptionId: string;
      priceId?: string | null | undefined;
    };
  }) => void;
  updatePayment?: any;
  isPendingChange: boolean;
}

// Tạo Context
export const PaddleContext = createContext<PaddleContextType | undefined>(
  undefined,
);

export const PaddleProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const [paddle, setPaddle] = useState<Paddle>();
  const { refetchPlan, activeTeamTier } = useTeamTier();
  const { user } = useAuth();

  const { mutate: updateSubscription, isPending: pendingChangeSubscription } =
    useMutation({
      mutationFn: async ({
        subscriptionId,
        priceId,
      }: {
        subscriptionId: string;
        priceId: string | null | undefined;
      }) => {
        const response = await axios.put(
          `/api/tier/update?subscription_id=${subscriptionId}${priceId ? `&price_id=${priceId}` : ''}`,
        );

        return response.data;
      },
      onSuccess: () => {
        toast({
          title: 'Update success',
          description: 'Your plan has been updated',
        });
        refetchPlan();
      },
    });

  const { mutate: updatePayment, isPending: pendingChangePayment } =
    useMutation({
      mutationKey: [activeTeamTier?.team?.subscriptionId],
      mutationFn: async () => {
        return (
          await axios.get('/api/tier/update', {
            params: { subscription_id: activeTeamTier?.team?.subscriptionId },
          })
        )?.data;
      },
      onSuccess: (data) => {
        paddle?.Checkout?.open({ transactionId: data?.id });
      },
    });

  useEffect(() => {
    if (typeof window !== 'undefined') {
      const vendorId = process.env.NEXT_PUBLIC_PADDLE_VENDOR_ID;
      const token = process.env.NEXT_PUCLIC_PADDLE_CLIENT_TOKEN;
      const environment = process.env.NEXT_PUBLIC_PADDLE_ENV;

      initializePaddle({
        seller: Number(vendorId),
        token: token as never,
        environment: environment as 'sandbox' | 'production',
      }).then((paddleInstance) => {
        if (paddleInstance) {
          setPaddle(paddleInstance);
        }
      });
    }
  }, []);

  const openCheckout = ({
    newCheckout,
    updateCheckout,
  }: {
    newCheckout?: {
      teamId: string;
      priceId: string;
      quantity?: number;
      successUrl?: string;
    };
    updateCheckout?: {
      subscriptionId: string;
      priceId?: string | null | undefined;
    };
  }) => {
    if (newCheckout) {
      const { priceId, quantity, successUrl, teamId } = newCheckout;

      paddle?.Checkout.open({
        items: [{ priceId, quantity }],
        settings: {
          successUrl,
        },
        ...(user?.email
          ? {
              customer: {
                email: user?.email,
              },
            }
          : ''),
        customData: {
          teamId,
        },
      });
    } else if (updateCheckout) {
      const { subscriptionId, priceId } = updateCheckout;

      updateSubscription({ subscriptionId, priceId });
    }
  };

  const isPendingChange = pendingChangePayment || pendingChangeSubscription;

  return (
    <PaddleContext.Provider
      value={{ paddle, openCheckout, updatePayment, isPendingChange }}
    >
      {children}
    </PaddleContext.Provider>
  );
};
