'use client';

import {
  GetProjectsOfTeamRequest,
  GetProjectsOfTeamResponse,
} from '@formo/shared';
import { PLANS_NAMES, PLAN_PADDLE_IDS } from '@formo/shared';
import {
  QueryObserverResult,
  RefetchOptions,
  useQueries,
  useQuery,
} from '@tanstack/react-query';
import axios from 'axios';
import {
  Dispatch,
  ReactNode,
  SetStateAction,
  createContext,
  useMemo,
  useState,
} from 'react';
import useDashboard from '~/app/hooks/useDashboard';
import client from '~/lib/client';
import tinybirdClient from '~/lib/tinybird.client';
import dayjs from '~/utils/dayjs';

import useFormTable from '../hooks/useFormTable';

export interface TeamTier {
  type: string;
  priority: number;
  billingPeriod: string;
  billingStart: Date;
  billingEnd: Date;
  customerId: string | null;
  subscriptionId: string | null;
  team: any;
  scheduled_change: any | null;
  status: string;
}

type SavedPayment =
  | {
      cardholder_name: string;
      type: string;
      last4: string;
      expiry_month: number;
      expiry_year: number;
    }
  | {
      email: string;
      reference: string;
      type: string;
    }
  | {
      type: string;
    };

interface TeamTierContextType {
  currentPeriod: 'annual' | 'monthly';
  setCurrentPeriod: Dispatch<SetStateAction<'annual' | 'monthly'>>;
  activeTeamTier: TeamTier;
  currentEventsCount: number;
  currentUsersCount: number;
  currentResponsesCount: number;
  isLoading: boolean;
  refetchPlan: (
    options?: RefetchOptions,
  ) => Promise<QueryObserverResult<any, Error>>;

  savedPayment: SavedPayment;
  credit: any;
}

export const TeamTierContext = createContext<TeamTierContextType | undefined>(
  undefined,
);

const getTierAndBillingPeriod = (priceId: string) => {
  for (const [tier, periods] of Object.entries(PLAN_PADDLE_IDS)) {
    for (const [period, id] of Object.entries(periods)) {
      if (id.includes(priceId)) return { tier, billingPeriod: period };
    }
  }
  return null;
};

export function TeamTierProvider({ children }: { children: ReactNode }) {
  const { activeTeam, isLoadingTeams } = useDashboard();
  const { activeForms } = useFormTable();
  const [currentPeriodOnClient, setCurrentPeriodOnClient] = useState<
    'annual' | 'monthly'
  >('annual');

  const { data: projects } = useQuery<
    GetProjectsOfTeamRequest,
    Error,
    GetProjectsOfTeamResponse
  >({
    queryKey: ['teams', 'projects', activeTeam?.teamId],
    queryFn: async () =>
      (
        await client.get(`/api/teams/${activeTeam?.teamId}/projects`, {
          params: {
            sortBy: 'updated_at',
            sortOrder: 'desc',
          },
        })
      ).data,
    refetchOnWindowFocus: false,
    enabled: !!activeTeam?.teamId,
  });

  const projectIds = projects?.map((project) => project.id) || [];

  const [
    { data: planData, refetch: refetchPlan },
    { data: eventsData },
    { data: usersData },
    { data: responsesData },
  ] = useQueries({
    queries: [
      {
        queryKey: [activeTeam?.team?.customerId, activeTeam?.team?.id],
        queryFn: async () =>
          (
            await axios.get('/api/tier/plan', {
              params: {
                subscription_id: activeTeam?.team.subscriptionId,
                customer_id: activeTeam?.team?.customerId,
              },
            })
          ).data,
        refetchOnWindowFocus: false,
        enabled: Boolean(activeTeam?.team.subscriptionId),
      },
      {
        queryKey: [projectIds, activeTeam?.team?.id, 'eventsData'],
        queryFn: async () => {
          return (
            await tinybirdClient.get('/pipes/event_count.json', {
              params: {
                projects: projectIds.join(','),
                date_from: dayjs().startOf('month').format('YYYY-MM-DD'),
                date_to: dayjs().endOf('month').format('YYYY-MM-DD'),
                useGlobalToken: true,
              },
            })
          ).data.data[0].total;
        },
        enabled: projectIds.length > 0,
        refetchOnWindowFocus: false,
        refetchInterval: 20000,
        refetchIntervalInBackground: true,
      },
      {
        queryKey: [projectIds, activeTeam?.team?.id, 'usersData'],
        queryFn: async () => {
          return (
            await tinybirdClient.get('/pipes/wallets_count.json', {
              params: {
                projects: projectIds.join(','),
                date_from: dayjs().startOf('month').format('YYYY-MM-DD'),
                date_to: dayjs().endOf('month').format('YYYY-MM-DD'),
                useGlobalToken: true,
              },
            })
          ).data.data[0].total;
        },
        enabled: projectIds.length > 0,
        refetchOnWindowFocus: false,
        refetchInterval: 20000,
        refetchIntervalInBackground: true,
      },
      {
        queryKey: [
          activeForms.map((form: any) => form.id),
          activeTeam?.team?.id,
        ],
        queryFn: async () =>
          (
            await client.get(
              `/api/teams/${activeTeam?.teamId}/forms/responses`,
              {
                params: {
                  formIds: activeForms.map((form: any) => form.id),
                  date_from: dayjs().endOf('month').format('YYYY-MM-DD'),
                  date_to: dayjs().startOf('month').format('YYYY-MM-DD'),
                },
              },
            )
          ).data,
        refetchOnWindowFocus: false,
        enabled: Boolean(activeTeam?.teamId) && activeForms.length > 0,
        refetchInterval: 20000,
        refetchIntervalInBackground: true,
      },
    ],
  });

  const activeTeamTier: TeamTier = useMemo(() => {
    return {
      team: activeTeam?.team,
      type:
        getTierAndBillingPeriod(planData?.plan?.items[0].price.id)?.tier ||
        'free',
      priority: Math.max(
        PLANS_NAMES.indexOf(
          getTierAndBillingPeriod(planData?.plan?.items[0].price.id)
            ?.tier as any,
        ),
        0,
      ),
      billingPeriod:
        getTierAndBillingPeriod(planData?.plan?.items[0].price.id)
          ?.billingPeriod || 'monthly',
      billingStart:
        planData?.plan?.current_billing_period?.starts_at ||
        dayjs().startOf('month').format('DD MMM, YYYY hh:mm A'),
      billingEnd:
        planData?.plan?.current_billing_period?.ends_at ||
        dayjs().endOf('month').format('DD MMM, YYYY hh:mm A'),

      customerId: activeTeam?.team?.customerId || null,
      subscriptionId: activeTeam?.team?.subscriptionId || null,
      scheduled_change: planData?.plan?.scheduled_change || null,
      status: planData?.plan?.status || 'active',
    };
  }, [planData?.plan, activeTeam]);

  const savedPayment = useMemo(() => {
    const payment = planData?.payment?.[0];
    if (!payment) return null;

    const { type } = payment;
    const paymentDetails = payment[type];

    return type !== 'card' ? { ...paymentDetails, type } : paymentDetails;
  }, [planData]);

  const value = useMemo(
    () => ({
      currentPeriod: currentPeriodOnClient,
      setCurrentPeriod: setCurrentPeriodOnClient,
      currentEventsCount: eventsData || 0,
      currentUsersCount: usersData || 0,
      currentResponsesCount: responsesData?.length || 0,
      activeTeamTier,
      isLoading: isLoadingTeams,
      refetchPlan: refetchPlan,
      savedPayment,
      credit: planData?.credit,
    }),
    [
      currentPeriodOnClient,
      activeTeamTier,
      isLoadingTeams,
      eventsData,
      usersData,
      refetchPlan,
      planData?.credit,
      savedPayment,
    ],
  );

  return (
    <TeamTierContext.Provider value={value}>
      {children}
    </TeamTierContext.Provider>
  );
}
