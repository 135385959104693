import { useContext } from 'react';
import { PaddleContext } from '~/app/context/PaddleContext';

export const usePaddle = () => {
  const context = useContext(PaddleContext);
  if (!context) {
    throw new Error('Must be use in Paddle Context');
  }
  return context;
};
