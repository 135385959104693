'use client';

import { useSearchParams } from 'next/navigation';
import { FC, Suspense, createContext, lazy, useEffect, useState } from 'react';

import { AddFieldPopoverProps } from '../_components/Builder/components/StepContentSection/components/AddFieldModal';
import {
  DeleteStepPopover,
  DeleteStepPopoverProps,
} from '../_components/Builder/components/StepsSection/components/DeleteStepPopover';
import { ErrorBoundary } from '../_components/common';

const AddFieldPopover = lazy(() =>
  import(
    '../_components/Builder/components/StepContentSection/components/AddFieldModal'
  ).then((module) => ({ default: module.AddFieldPopover })),
);

type OmitPopoverProps<T> = Omit<T, 'open' | 'setOpen'>;

type DeleteStepType = {
  type: 'delete_step';
} & OmitPopoverProps<DeleteStepPopoverProps>;

type AddFieldType = {
  type: 'add_field';
} & OmitPopoverProps<AddFieldPopoverProps>;

type OpenPopoverParams = DeleteStepType | AddFieldType;

type BuildFormPopoverContextType = {
  closePopover: () => void;
  // eslint-disable-next-line no-unused-vars
  openPopover: (props: OpenPopoverParams) => void;
  isOpen: boolean;
};

export const BuildFormPopoverContext =
  createContext<BuildFormPopoverContextType>({
    closePopover: () => {},
    openPopover: () => {},
    isOpen: false,
  });

const PopoverMapper = {
  delete_step: DeleteStepPopover,
  add_field: AddFieldPopover,
};

type BuildFormPopoverProviderProps = {
  children: React.ReactNode;
};

const BuildFormPopoverProvider: FC<BuildFormPopoverProviderProps> = ({
  children,
}) => {
  const searchParams = useSearchParams();
  const action = searchParams.get('action');
  const args = searchParams.get('args');

  const [isOpen, setIsOpen] = useState(() =>
    action ? action in PopoverMapper : false,
  );
  const [props, setProps] = useState<OpenPopoverParams | null>(() => {
    if (!action || !args)
      return {
        type: action,
      };
    try {
      return {
        type: action,
        ...JSON.parse(args),
      };
    } catch (error) {
      return {
        type: action,
      };
    }
  });

  const Popover = props ? PopoverMapper[props.type] : null;

  useEffect(() => {
    if (!isOpen && searchParams.get('action')) {
      window.history.replaceState(null, '', location.pathname);
    }
  }, [isOpen]);

  return (
    <BuildFormPopoverContext.Provider
      value={{
        closePopover: () => {
          setIsOpen(false);
          setProps(null);
        },
        openPopover: (newProps) => {
          setIsOpen(true);
          setProps(newProps);
        },
        isOpen,
      }}
    >
      {children}
      <ErrorBoundary
        onError={() => {
          setIsOpen(false);
          setProps(null);
        }}
      >
        {Popover && (
          <Suspense fallback={<div>Loading...</div>}>
            <Popover open={isOpen} setOpen={setIsOpen} {...(props as any)} />
          </Suspense>
        )}
      </ErrorBoundary>
    </BuildFormPopoverContext.Provider>
  );
};

export default BuildFormPopoverProvider;
