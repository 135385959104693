import { StepSchema } from '@formo/types';
import { DraggableProvided } from '@hello-pangea/dnd';
import { StepType } from '~/constants/fields';

import { StepTab } from '../StepsSection/components/StepTab';

export const renderStepTabs = (
  provided: DraggableProvided,
  step: StepSchema,
  selectedStep: StepSchema,
  handleTabClick: (page: StepSchema) => void,
  index: number,
  shouldDisableDelete?: boolean,
) => {
  return (
    <StepTab
      provided={provided}
      key={step.id}
      id={step.id}
      index={step.type !== StepType.ENDING ? index + 1 : undefined}
      type={step.type}
      label={step.name}
      active={selectedStep?.id === step.id}
      onClick={() => handleTabClick(step)}
      shouldDisableDelete={!!shouldDisableDelete}
    />
  );
};

// Helper function to capitalize the first letter of a string
export const capitalizeFirstLetter = (text: string) => {
  return text.charAt(0).toUpperCase() + text.slice(1);
};
