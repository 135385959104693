import { FC, useEffect, useRef } from 'react';
import useFormBuilder from '~/app/hooks/useFormBuilder';
import { Button } from '~/components/ui/button';
import { Popover, PopoverContent } from '~/components/ui/popover';

type Position = { top: number; left: number };

export type DeleteStepPopoverProps = {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  onSubmit?: () => void;
  formId: string;
  stepId: string;
  position: Position;
};

const DeleteStepPopover: FC<DeleteStepPopoverProps> = ({
  open,
  setOpen,
  stepId,
  formId,
  onSubmit,
  position,
}) => {
  const { deleteStep } = useFormBuilder();

  const popoverRef = useRef<HTMLDivElement>(null);

  const handleSubmit = () => {
    if (!(formId || stepId)) return;

    deleteStep(stepId);
    setOpen(false);
    onSubmit?.();
  };

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        popoverRef.current &&
        !popoverRef.current.contains(event.target as Node)
      ) {
        setOpen(false);
      }
    };

    const handleScroll = () => {
      setOpen(false);
    };

    document.addEventListener('mousedown', handleClickOutside);
    document.addEventListener('scroll', handleScroll, true);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
      document.removeEventListener('scroll', handleScroll, true);
    };
  }, [setOpen]);

  return (
    <Popover
      open={open}
      onOpenChange={(value) => {
        setOpen((prev) => (prev === value ? prev : value));
      }}
    >
      <PopoverContent
        ref={popoverRef}
        style={{
          position: 'absolute',
          top: position.top - 195,
          left: position.left + 60,
        }}
        className="z-50 rounded-[6px] border border-black/5 bg-white p-0 shadow data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=open]:fade-in-0 data-[state=closed]:zoom-out-95 data-[state=open]:zoom-in-95 data-[side=bottom]:slide-in-from-top-10 data-[side=left]:slide-in-from-right-10 data-[side=right]:slide-in-from-left-10 data-[side=top]:slide-in-from-bottom-2"
      >
        <div className="absolute left-[-8px] top-1/2 -z-10 h-0 w-0 -translate-y-1/2 transform rounded-full border-b-8 border-r-8 border-t-8 border-b-transparent border-r-black/5 border-t-transparent">
          <div className="absolute left-[1px] top-0 z-10 h-0 w-0 -translate-y-1/2 transform rounded-full border-b-8 border-r-8 border-t-8 border-b-transparent border-r-white border-t-transparent"></div>
        </div>
        <div className="relative flex flex-col justify-center gap-1.5 rounded-lg px-7 py-3 text-center">
          <div className="flex justify-center">
            <img src="/icons/alert-modal.svg" className="h-8 w-8" />
          </div>
          <div className="text-sm font-medium">Delete page?</div>
          <div className="text-xs text-gray-700">
            All questions on this page will be deleted.
          </div>
          <div className="mt-2 flex flex-row justify-center gap-1.5 sm:justify-center">
            <Button
              type="button"
              variant="outline"
              onClick={() => setOpen(false)}
              className="h-7.5 cursor-pointer rounded-lg px-7 py-1.5 text-sm text-red-500 hover:bg-red-50 hover:text-red-500"
            >
              Cancel
            </Button>

            <Button
              type="button"
              variant="outline"
              onClick={handleSubmit}
              className="h-7.5 rounded-lg border-1.5 border-white/30 bg-red-500 px-7 py-1.5 text-sm text-white hover:bg-red-500/90 hover:text-white"
            >
              Delete
            </Button>
          </div>
        </div>
      </PopoverContent>
    </Popover>
  );
};

export default DeleteStepPopover;
