import { useContext } from 'react';
import { TeamTierContext } from '~/app/context/TeamTierContext';

export function useTeamTier() {
  const context = useContext(TeamTierContext);
  if (!context) {
    throw new Error('useTeamTier must be used within a TeamTierProvider');
  }
  return context;
}
