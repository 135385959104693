import { PLAN_PADDLE_IDS } from '@formo/shared';
import { FormSchema } from '@formo/types';
import { Button } from '@formo/ui';
import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import { MoveRightIcon } from 'lucide-react';
import React, { FC, useCallback } from 'react';
import { PaddleProvider } from '~/app/context/PaddleContext';
import { TeamTier } from '~/app/context/TeamTierContext';
import useDashboard from '~/app/hooks/useDashboard';
import useDashboardModal from '~/app/hooks/useDashboardModal';
import { usePaddle } from '~/app/hooks/usePaddle/usePaddle';
import { useTeamTier } from '~/app/hooks/useTeamTier/useTeamTier';
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
} from '~/components/ui/dialog';

import { capitalizeFirstLetter } from '../../Builder/components/common/helpers';
import { Spinner } from '../../common';

export type ChangePlanPeriodModalProps = {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  onSubmit?: (form: FormSchema) => void;
  activeTier: TeamTier;
  newTier: Omit<TeamTier, 'billingStart' | 'billingEnd' | 'customerId'>;
};

const usePreviewFee = (
  activeTier: TeamTier,
  newTier: Omit<TeamTier, 'billingStart' | 'billingEnd' | 'customerId'>,
) => {
  const { activeTeamTier } = useTeamTier();
  const { data, isFetching } = useQuery({
    retry: 2,
    queryKey: [newTier?.type, newTier?.billingPeriod],
    enabled: newTier?.priority !== 0,
    queryFn: async () => {
      const { data } = await axios.get('/api/tier/preview', {
        params: {
          ...(activeTeamTier?.scheduled_change
            ? {
                scheduled_change: JSON.stringify(
                  activeTeamTier?.scheduled_change,
                ),
              }
            : {}),
          subscription_id: activeTier?.subscriptionId,
          ...(PLAN_PADDLE_IDS[newTier?.type as keyof typeof PLAN_PADDLE_IDS]?.[
            newTier?.billingPeriod as 'monthly' | 'annual'
          ]?.[0]
            ? {
                price_id:
                  PLAN_PADDLE_IDS[
                    newTier?.type as keyof typeof PLAN_PADDLE_IDS
                  ][newTier?.billingPeriod as 'monthly' | 'annual'][0],
              }
            : {}),
        },
      });
      return data?.data;
    },
  });
  return { data, isFetching };
};

const ChangePlanPeriodButton: FC<{
  newType: string;
  newPeriod: 'annual' | 'monthly';
}> = React.memo(({ newType, newPeriod }) => {
  const { openCheckout } = usePaddle();
  const { activeTeam } = useDashboard();
  const { closeModal } = useDashboardModal();

  const handleClick = useCallback(() => {
    if (activeTeam?.team?.subscriptionId) {
      openCheckout({
        updateCheckout: {
          subscriptionId: activeTeam?.team?.subscriptionId ?? '',
          ...(PLAN_PADDLE_IDS[newType as keyof typeof PLAN_PADDLE_IDS]?.[
            newPeriod as 'monthly' | 'annual'
          ]?.[0]
            ? {
                priceId:
                  PLAN_PADDLE_IDS[newType as keyof typeof PLAN_PADDLE_IDS][
                    newPeriod as 'monthly' | 'annual'
                  ][0],
              }
            : {}),
        },
      });
    }

    closeModal();
  }, [activeTeam, closeModal, newType, newPeriod, openCheckout]);

  return (
    <Button onClick={handleClick} className="w-fit whitespace-nowrap">
      Confirm
    </Button>
  );
});

const ChangePlanPeriodFormModal: FC<ChangePlanPeriodModalProps> = ({
  open,
  setOpen,
  activeTier,
  newTier,
}) => {
  const { data: previewFee, isFetching } = usePreviewFee(activeTier, newTier);

  return (
    <PaddleProvider>
      <Dialog open={open} onOpenChange={(value) => setOpen(value)}>
        <DialogContent className="max-h-[80lvh] w-[600px] gap-0 overflow-y-auto p-0 rounded-lg">
          <DialogHeader>
            <DialogTitle className="px-6 py-4 b1 font-semibold">
              {newTier?.priority === 0
                ? `Cancel ${capitalizeFirstLetter(activeTier.type)} plan ?`
                : 'Change plan'}
            </DialogTitle>
          </DialogHeader>
          <div className="border-b-1.5 border-black/5"></div>
          <div className="px-6 pb-9 pt-4">
            {isFetching ? (
              <Spinner />
            ) : (
              <>
                <div className="text-content-secondary flex flex-wrap gap-1 items-center">
                  <div className="inline-flex items-center gap-1">
                    You are switching from
                    {activeTier?.type !== newTier?.type && (
                      <>
                        <span className="text-content-primary">
                          {capitalizeFirstLetter(activeTier?.type ?? '')}
                        </span>{' '}
                        <MoveRightIcon className="mx-1 w-4 h-4" />
                        <span className="text-content-primary">
                          {capitalizeFirstLetter(newTier?.type ?? '')}
                        </span>
                      </>
                    )}
                    {newTier?.priority !== 0 &&
                      activeTier?.type !== newTier?.type &&
                      activeTier?.billingPeriod !== newTier?.billingPeriod &&
                      ' and '}
                    {newTier?.priority !== 0 &&
                      activeTier?.billingPeriod !== newTier?.billingPeriod && (
                        <>
                          <span className="text-content-primary">
                            {capitalizeFirstLetter(
                              activeTier?.billingPeriod ?? '',
                            )}
                          </span>{' '}
                          <MoveRightIcon className="mx-1 w-4 h-4" />
                          <span className="text-content-primary">
                            {capitalizeFirstLetter(
                              newTier?.billingPeriod ?? '',
                            )}
                          </span>
                        </>
                      )}
                  </div>
                  .
                  <div>
                    You will be charged{' '}
                    <span className="text-content-primary">
                      $
                      {previewFee?.update_summary
                        ? previewFee?.update_summary.result.action === 'credit'
                          ? 0
                          : previewFee?.update_summary.result.amount / 100
                        : 0}
                    </span>
                    {previewFee?.update_summary?.result.action === 'credit' && (
                      <>
                        {' '}
                        and receive{' '}
                        <span className="text-content-primary">
                          ${previewFee?.update_summary.result.amount / 100}
                        </span>{' '}
                        workspace credits
                      </>
                    )}{' '}
                  </div>
                </div>

                <div className="mt-6 flex gap-2 justify-end">
                  <Button onClick={() => setOpen(false)} variant="text">
                    Cancel
                  </Button>
                  <ChangePlanPeriodButton
                    newType={newTier?.type ?? ''}
                    newPeriod={newTier?.billingPeriod as 'monthly' | 'annual'}
                  />
                </div>
              </>
            )}
          </div>
        </DialogContent>
      </Dialog>
    </PaddleProvider>
  );
};

export default ChangePlanPeriodFormModal;
