import axios, { AxiosError, HttpStatusCode } from 'axios';
import { PROJECT_READ_TOKEN_KEY_NAME } from '~/constants/projects';
import { formoSessionStorage } from '~/utils/storage';

import client from './client';

const tinybirdClient = axios.create({
  baseURL: process.env.NEXT_PUBLIC_TINYBIRD_URL,
  headers: {
    'Content-Type': 'application/json',
  },
});

tinybirdClient.interceptors.request.use(
  (config) => {
    const { useGlobalToken = false, ...params } = config.params;
    if (useGlobalToken) {
      // Use global token if `useGlobalToken` is specified in params
      config.headers['Authorization'] =
        'Bearer ' + process.env.NEXT_PUBLIC_TINYBIRD_GLOBAL_READ_TOKEN;
      // Then remove `useGlobalToken` from params
      return {
        ...config,
        params,
      };
    } else {
      // Use project token
      const token = formoSessionStorage.getItem(
        PROJECT_READ_TOKEN_KEY_NAME(params.project),
      );
      if (token)
        return {
          ...config,
          params: {
            ...params,
            token,
          },
        };
    }

    throw new Error('Token not provided');
  },
  (error) => {
    return Promise.reject(error);
  },
);

let isRefreshing = false;
let refreshSubscribers: any[] = [];

const subscribeTokenRefresh = (cb: any) => {
  refreshSubscribers.push(cb);
};

const onRefreshed = (token: string) => {
  refreshSubscribers.map((cb) => cb(token));
};

tinybirdClient.interceptors.response.use(
  (res) => {
    return res;
  },
  async (error: AxiosError<any>) => {
    // Check if response status is 403
    if (error.response?.status === HttpStatusCode.Forbidden) {
      // Re-call original 403 requests
      const retryOriginalRequest = new Promise((resolve) => {
        subscribeTokenRefresh((token: string) => {
          resolve(
            tinybirdClient.request({
              ...error.config,
              params: {
                ...error.config?.params,
                token,
              },
            }),
          );
        });
      });

      if (!isRefreshing) {
        isRefreshing = true;

        try {
          const projectId = error.response!.config.params?.project;
          const token = formoSessionStorage.getItem(
            PROJECT_READ_TOKEN_KEY_NAME(projectId),
          );
          if (!token) throw new Error('Project read token does not exist');
          const res = await client.post('/api/projects/refresh', {
            token,
          });
          const newToken = res.data?.token;
          if (!newToken) throw new Error('New project read token is invalid');
          onRefreshed(newToken);
          formoSessionStorage.setItem(
            PROJECT_READ_TOKEN_KEY_NAME(projectId),
            newToken,
          );
        } catch (err: any) {
          return Promise.reject(err);
        } finally {
          isRefreshing = false;
          refreshSubscribers = [];
        }
      }

      return retryOriginalRequest;
    }
    return Promise.reject(error);
  },
);

export default tinybirdClient;
