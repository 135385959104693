'use client';

import { DraggableProvided } from '@hello-pangea/dnd';
import { PopoverClose } from '@radix-ui/react-popover';
import React, { useState } from 'react';
import useBuildFormPopover from '~/app/hooks/useBuildFormPopover';
import useFormBuilder from '~/app/hooks/useFormBuilder';
import { Input } from '~/components/ui/input';
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from '~/components/ui/popover';
import { StepAction, StepType } from '~/constants/fields';
import { cn } from '~/lib/utils';

const stepActions = [
  {
    text: 'Rename',
    id: StepAction.RENAME,
  },
  {
    text: 'Duplicate',
    id: StepAction.DUPLICATE,
  },
  {
    text: 'Delete',
    id: StepAction.DELETE,
  },
];

type StepTabProps = {
  provided?: DraggableProvided;
  id: string;
  label: string;
  active: boolean;
  type: string;
  index?: number;
  onClick: (id: string) => void;
  shouldDisableDelete: boolean;
};

const StepTab: React.FC<StepTabProps> = ({
  provided,
  id,
  label,
  active,
  index,
  type,
  onClick,
  shouldDisableDelete,
}) => {
  const { openPopover } = useBuildFormPopover();
  const { form, selectedStep, handleDuplicateStep, renameStep } =
    useFormBuilder();
  const [hoveredStepId, setHoveredStep] = useState<string | null>(null);
  const [isRenaming, setIsRenaming] = useState(false);
  const [newLabel, setNewLabel] = useState(label);
  const isSelected = selectedStep ? id === selectedStep.id : false;

  const handleActionClick = (
    action: StepAction,
    event: React.MouseEvent<HTMLButtonElement>,
  ) => {
    const rect = event.currentTarget.getBoundingClientRect();
    const position = { top: rect.top, left: rect.left };

    if (action === StepAction.DELETE) {
      if (!form) return;

      openPopover({
        type: 'delete_step',
        formId: form.id,
        stepId: id,
        position,
      });
    } else if (action === StepAction.DUPLICATE) {
      handleDuplicateStep(selectedStep);
    } else if (action === StepAction.RENAME) {
      setIsRenaming(true);
    }
  };

  const handleRename = () => {
    renameStep(id, newLabel);
    setIsRenaming(false);
  };

  return (
    <>
      <li
        ref={provided?.innerRef}
        key={id}
        role="presentation"
        className={`relative mx-2 flex items-center rounded-md p-1 pl-4 text-left font-normal ${active ? 'border-none bg-black/5 bg-opacity-60 text-black' : 'bg-inherit text-gray-900 dark:border-gray-700 dark:bg-gray-800 dark:text-gray-400'}`}
      >
        {provided && (
          <div
            {...provided.dragHandleProps}
            className="relative flex w-full items-center"
          >
            <div>
              <span
                className={cn(
                  'mr-3 flex h-6 min-w-6 cursor-pointer border border-gray-100 items-center justify-center rounded-9 bg-white',
                  active || type === StepType.ENDING
                    ? 'border-black/10'
                    : 'border-none',
                )}
              >
                {type === StepType.ENDING ? (
                  <img src="/icons/ending.svg" />
                ) : type === StepType.WELCOME ? (
                  <img src="/icons/welcome-page.svg" />
                ) : (
                  index
                )}
              </span>
            </div>
            <div
              id={`draggable-${id}`}
              data-tabs-target={`#${id}`}
              role="tab"
              aria-controls={id}
              aria-selected={active ? 'true' : 'false'}
              onClick={() => onClick(id)}
              onMouseEnter={() => setHoveredStep(id)}
              onMouseLeave={() => setHoveredStep(null)}
              className="relative flex min-h-[34px] w-[80%] items-center justify-between"
            >
              <div className="max-w-2/3 flex items-center justify-between sm:w-[25%] md:w-[30%] lg:w-[50%] xl:w-[66%] 2xl:w-[70%]">
                {isRenaming ? (
                  <Input
                    type="text"
                    value={newLabel}
                    onChange={(e) => setNewLabel(e.target.value)}
                    onBlur={handleRename}
                    onKeyDown={(e) => {
                      if (e.key === 'Enter') {
                        handleRename();
                      } else if (e.key === 'Escape') {
                        setIsRenaming(false);
                        setNewLabel(label);
                      }
                    }}
                    className="h-[22px] w-full rounded-md border-[0.8px] border-gray-500 bg-white p-1 text-sm outline-none focus:border-gray-500 focus-visible:outline-none focus-visible:ring-0 focus-visible:ring-offset-0"
                    autoFocus
                  />
                ) : (
                  <p className="truncate text-ellipsis whitespace-nowrap">
                    {newLabel}
                  </p>
                )}
              </div>
            </div>
            {(isSelected || hoveredStepId === id) && (
              <div
                id="three-dots"
                className="absolute right-2 min-w-2 border-none text-right"
                onMouseEnter={() => setHoveredStep(id)}
              >
                <Popover>
                  <PopoverTrigger
                    className="flex items-center"
                    onMouseEnter={() => setHoveredStep(id)}
                  >
                    <img
                      src="/icons/three-dots.svg"
                      className="max-h-4 min-w-6 opacity-50"
                    />
                  </PopoverTrigger>
                  <PopoverContent className="max-w-28 border border-black/10 p-0">
                    <ul>
                      {stepActions.map(({ id, text }) => (
                        <li
                          key={id}
                          className={cn(
                            'px-3.5 py-2.5 first:rounded-t-md last:rounded-b-md',
                            id === StepAction.DELETE
                              ? `${!shouldDisableDelete ? 'border-t border-black-10 hover:bg-red-50' : 'hidden'}`
                              : 'hover:bg-black/5',
                            (type === StepType.ENDING ||
                              type === StepType.WELCOME) &&
                              id === StepAction.DUPLICATE &&
                              'hidden',
                          )}
                        >
                          <PopoverClose>
                            <button
                              onClick={(event) => handleActionClick(id, event)}
                              className={cn(
                                'cursor-pointer disabled:cursor-not-allowed',
                                id === StepAction.DELETE
                                  ? `${!shouldDisableDelete ? 'text-red-500' : 'text-red-50'}`
                                  : '',
                              )}
                              disabled={
                                (type === StepType.ENDING &&
                                  id === StepAction.DUPLICATE) ||
                                (shouldDisableDelete &&
                                  type === StepType.ENDING &&
                                  id === StepAction.DELETE)
                              }
                            >
                              {text}
                            </button>
                          </PopoverClose>
                        </li>
                      ))}
                    </ul>
                  </PopoverContent>
                </Popover>
              </div>
            )}
          </div>
        )}
      </li>
    </>
  );
};

export default StepTab;
