import {
  ArchiveFormRequest,
  ArchiveFormResponse,
  DeleteFormRequest,
  DeleteFormResponse,
  DuplicateFormRequest,
  DuplicateFormResponse,
  GetFormsOfTeamResponse,
  UnarchiveFormRequest,
  UnarchiveFormResponse,
  UpdateFormRequest,
  UpdateFormResponse,
} from '@formo/shared';
import { FormSchema } from '@formo/types';
import { useMutation, useQuery } from '@tanstack/react-query';
import dayjs from 'dayjs';
import { useRouter } from 'next/navigation';
import { FC, createContext, useCallback, useMemo, useState } from 'react';
import { useToast } from '~/components/ui/use-toast';
import { FORM_STATUS } from '~/constants/status';
import client from '~/lib/client';

import useDashboard from '../hooks/useDashboard';
import useDashboardModal from '../hooks/useDashboardModal';

const tabs = ['Active', 'Archived'] as const;
type Tab = (typeof tabs)[number];
type Row = GetFormsOfTeamResponse[number];

type FormTableContextType = {
  // Tab
  tabs: readonly Tab[];
  activeTab: Tab;
  setActiveTab: (tab: Tab) => void;
  // Form
  forms: Row[];
  responses: string[];
  activeForms: Row[];
  isFetching: boolean;
  refetchForms: () => Promise<any>;
  // Action
  onCreate: () => void;
  isArchiving: boolean;
  onArchive: (form: Row) => Promise<void>;
  onUnarchive: (form: Row) => Promise<void>;
  isDuplicating: boolean;
  onDuplicate: (form: Row) => Promise<void>;
  isUpdating: boolean;
  onUpdate: (
    formId: string,
    data: UpdateFormRequest['params'] & UpdateFormRequest['body'],
  ) => Promise<void>;
  isDeleting: boolean;
  onDelete: (form: Row) => Promise<void>;
  popover: Popover | null;
  setPopover: (popover: Popover | null) => void;
};

export const FormTableContext = createContext<FormTableContextType>({
  tabs: [],
  activeTab: 'Active',
  setActiveTab: () => {},
  forms: [],
  responses: [],
  activeForms: [],
  isFetching: false,
  refetchForms: async () => {},
  onCreate: () => {},
  isArchiving: false,
  onArchive: async () => {},
  onUnarchive: async () => {},
  isDuplicating: false,
  onDuplicate: async () => {},
  isUpdating: false,
  onUpdate: async () => {},
  isDeleting: false,
  onDelete: async () => {},
  popover: null,
  setPopover: () => {},
});

type FormTableProviderProps = {
  children: React.ReactNode;
};

type Popover = {
  id: string;
  type: 'delete';
};

const FormTableProvider: FC<FormTableProviderProps> = ({ children }) => {
  const { activeTeam } = useDashboard();
  const { openModal } = useDashboardModal();
  const router = useRouter();
  const { toast } = useToast();
  const [popover, setPopover] = useState<Popover | null>(null);

  const {
    data,
    isFetching,
    refetch: refetchForms,
  } = useQuery({
    queryKey: ['teams', 'forms', activeTeam?.teamId],
    queryFn: async () =>
      (
        await client.get(`/api/teams/${activeTeam?.teamId}/forms`, {
          params: {
            sortBy: 'updated_at',
            sortOrder: 'desc',
          },
        })
      ).data,
    refetchOnWindowFocus: false,
    enabled: !!activeTeam?.teamId,
  });

  const [activeTab, setActiveTab] = useState<Tab>(() => {
    const hash = window.location.hash.slice(1) as Tab;
    if (!hash || !tabs.includes(hash)) return 'Active';
    return hash;
  });

  const activeForms = useMemo(() => {
    return (
      data?.filter((form: FormSchema) => {
        return form.status !== FORM_STATUS.ARCHIVED;
      }) ?? []
    );
  }, [data]);

  const forms = useMemo(
    () =>
      data?.filter((form: FormSchema) => {
        if (activeTab === 'Active') return form.status !== FORM_STATUS.ARCHIVED;
        if (activeTab === 'Archived')
          return form.status === FORM_STATUS.ARCHIVED;
        return true;
      }) ?? [],
    [data, activeTab],
  );

  const { data: responses } = useQuery({
    queryKey: [forms.map((form: any) => form.id)],
    queryFn: async () =>
      (
        await client.get(`/api/teams/${activeTeam?.teamId}/forms/responses`, {
          params: {
            formIds: activeForms.map((form: any) => form.id),
            billingStartAt: dayjs().startOf('month').format('YYYY-MM-DD'),
            billingEndAt: dayjs().endOf('month').format('YYYY-MM-DD'),
          },
        })
      ).data,
    refetchOnWindowFocus: false,
    enabled: Boolean(activeTeam?.teamId) && forms.length > 0,
  });

  const { mutateAsync: duplicateForm, isPending: isDuplicating } = useMutation<
    DuplicateFormResponse,
    Error,
    DuplicateFormRequest['params']
  >({
    mutationFn: async ({ teamId, formId }) =>
      (await client.post(`/api/teams/${teamId}/forms/${formId}/duplicate`))
        .data,
  });

  const { mutateAsync: updateForm, isPending: isUpdating } = useMutation<
    UpdateFormResponse,
    Error,
    UpdateFormRequest['body'] & UpdateFormRequest['params']
  >({
    mutationFn: async ({
      teamId,
      formId,
      title,
      description,
      theme,
      steps,
      settings,
    }) =>
      (
        await client.put(`/api/teams/${teamId}/forms/${formId}`, {
          title,
          description,
          theme,
          steps,
          settings,
        })
      ).data,
  });

  const { mutateAsync: removeForm, isPending: isDeleting } = useMutation<
    DeleteFormResponse,
    Error,
    DeleteFormRequest['params']
  >({
    mutationFn: async ({ teamId, formId }) =>
      (await client.delete(`/api/teams/${teamId}/forms/${formId}`)).data,
  });

  const { mutateAsync: archiveForm, isPending: isArchiving } = useMutation<
    ArchiveFormResponse,
    Error,
    ArchiveFormRequest['params']
  >({
    mutationFn: async ({ teamId, formId }) =>
      (await client.post(`/api/teams/${teamId}/forms/${formId}/archive`)).data,
  });

  const { mutateAsync: unarchiveForm, isPending: isUnarchiving } = useMutation<
    UnarchiveFormResponse,
    Error,
    UnarchiveFormRequest['params']
  >({
    mutationFn: async ({ teamId, formId }) =>
      (await client.post(`/api/teams/${teamId}/forms/${formId}/unarchive`))
        .data,
  });

  const onCreate = () => {
    if (!activeTeam) return;

    openModal({
      type: 'create_form',
      teamId: activeTeam.teamId,
      onSubmit: (form) => {
        router.push(`/${form.id}/edit`);
      },
    });
  };

  const onArchive = useCallback(
    async (form: Row) => {
      if (!activeTeam) return;
      try {
        await archiveForm({
          formId: form.id,
          teamId: activeTeam.teamId,
        });
        await refetchForms();
        toast({
          title: 'Form archived',
          description: `Form "${form.title}" has been archived`,
        });
      } catch (error: any) {
        toast({
          title: 'Failed to archive form',
          description: error.message,
        });
      }
    },
    [activeTeam?.teamId, refetchForms],
  );

  const onUnarchive = useCallback(
    async (form: Row) => {
      if (!activeTeam) return;
      try {
        await unarchiveForm({
          formId: form.id,
          teamId: activeTeam.teamId,
        });
        await refetchForms();
        toast({
          title: 'Form unarchived',
          description: `Form "${form.title}" has been unarchived`,
        });
      } catch (error: any) {
        toast({
          title: 'Failed to unarchive form',
          description: error.message,
        });
      }
    },
    [activeTeam?.teamId, refetchForms],
  );

  const onDelete = useCallback(
    async (form: Row) => {
      if (!activeTeam) return;
      try {
        await removeForm({
          formId: form.id,
          teamId: activeTeam.teamId,
        });
        await refetchForms();
        toast({
          title: 'Form deleted',
          description: `Form "${form.title}" has been deleted`,
        });
      } catch (error: any) {
        toast({
          title: 'Failed to delete form',
          description: error.message,
        });
      }
    },
    [activeTeam?.teamId, refetchForms],
  );

  const onDuplicate = useCallback(
    async (form: Row) => {
      if (!activeTeam) return;

      try {
        await duplicateForm({
          formId: form.id,
          teamId: activeTeam?.teamId,
        });
        await refetchForms();
        toast({
          title: 'Form duplicated',
          description: `Form "${form.title}" has been duplicated`,
        });
      } catch (error: any) {
        toast({
          title: 'Failed to duplicate form',
          description: error.message,
        });
      }
    },
    [activeTeam?.teamId, refetchForms],
  );

  const onUpdate = useCallback(
    async (
      formId: string,
      data: UpdateFormRequest['params'] & UpdateFormRequest['body'],
    ) => {
      if (!activeTeam?.teamId) return;

      try {
        await updateForm({
          ...data,
        });
        await refetchForms();
      } catch (error: any) {
        toast({
          title: 'Failed to update form',
          description: error.message,
        });
      }
    },
    [activeTeam?.teamId, refetchForms],
  );

  return (
    <FormTableContext.Provider
      value={{
        tabs,
        setActiveTab,
        activeTab,
        forms,
        responses,
        activeForms,
        isFetching,
        refetchForms,
        isArchiving: isArchiving || isUnarchiving,
        onArchive,
        onUnarchive,
        onCreate,
        isDuplicating,
        onDuplicate,
        onDelete,
        isUpdating,
        onUpdate,
        isDeleting,
        popover,
        setPopover,
      }}
    >
      {children}
    </FormTableContext.Provider>
  );
};

export default FormTableProvider;
